@if(isBrowser && !product && isLoading$){
  <div class="container" style="margin-bottom: 0px">
    <div class="row">
      <div class="col-md-6 m-4">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'height.px': 30,
            'background-color': '#e4e4e4',
            
          }"
          [animation]="false"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div class="row ml-4 mb-4">
      <div class="col-md-6">
        <ngx-skeleton-loader
          [theme]="{
            'height.px': 500,
            'background-color': '#e4e4e4'
          }"
          [animation]="false"
        >
        </ngx-skeleton-loader>
      </div>
      <div class="col-md-6">
        <ngx-skeleton-loader
          [theme]="{
            'height.px': 80,
            'background-color': '#e4e4e4'
          }"
          [animation]="false"
        ></ngx-skeleton-loader>
        <div class="col-md-8">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 58,
              'background-color': '#e4e4e4'
            }"
            [animation]="false"
          ></ngx-skeleton-loader>
        </div>
        <div class="row">
          <div class="col-md-3">
            <ngx-skeleton-loader
              [theme]="{
                'height.px': 50,
                'background-color': '#e4e4e4'
              }"
              [animation]="false"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-md-9">
            <ngx-skeleton-loader
              [theme]="{
                'height.px': 50,
                'background-color': '#e4e4e4'
              }"
              [animation]="false"
            ></ngx-skeleton-loader>
          </div>
        </div>
        <div class="col-md-12">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 100,
              'background-color': '#e4e4e4'
            }"
            [animation]="false"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-md-12">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 150,
              'background-color': '#e4e4e4'
            }"
            [animation]="false"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
  } @else{
  <div *ngIf="product && !isLoading$">
    <section class="pt-3 breadcrumbs">
      <ng-container *ngIf="breadcrumbs && breadcrumbs.length > 0">
        <ea-breadcrumb [items]="breadcrumbs"></ea-breadcrumb>
      </ng-container>
      <div class="container pt-2">
        <div class="mb-8"></div>
        <div class="row" itemscope >
          <div
            class="product-display-right pe-5 col-sm-12 col-md-12 col-lg-6 col-xl-6"
          >

          <div class=" mobile" style="background-color: #181618; color: white; padding: 15px; border-radius: 5px;">
            <span class="">
              <span class="font-bold d-flex justify-content-center" style="font-size: 14px;">Free standard delivery on all orders over £28 </span>
             <span class="d-flex justify-content-center mt-1"  style="font-size: 15px;">Applies automatically on payment page</span> </span>
          </div>
          <br>
    
            <!-- PRODUCT IMAGE GALLERY-->
            <ng-container *ngIf="this.images" class="">
              <div class="product-image mainImage desktop">
                <img
                  itemprop="image"
                  [src]="
                    mainImage || 'assets/images/placeholder.png' | getImageFilter
                  "
                  [alt]="altMainImage"
                />
              </div>
  
              <div
              class="product-image-thumbnails desktop"
              *ngIf="this.images.length > 1"
            >
              <div class="col-auto d-flex justify-content-end text-right">
                  <button  class="arrow-icon ea-button ea-button-secondary m-2  ml-2" (click)="prevImage()" name="Pervious Image">
                    <img src="assets/icons/left-row.svg" style="width: 12px;" alt="left row">
                  </button>
      
                <button  class="arrow-icon ea-button ea-button-secondary m-2 mr-2" (click)="nextImage()" name="Next Image">
                  <img src="assets/icons/right-row.svg" style="width: 12px; height: 16.5px;"  alt="right row">
                </button>
 
              </div>
              <div class="row align-items-center bigger-row">
                <!-- Left arrow -->
        
                <!-- Image thumbnails -->
                <div class="col">
                  <div class="row justify-content-center">
                    <div
                      class="col-3 mb-4"
                      *ngFor="let image of this.images; let i = index"
                    >
                      <img
                        class="thumbnail-active bigger-thumbnail"
                        [ngClass]="{ active: i === currentIndex }"
                        [src]="image.imageURL | getImageFilter"
                        [alt]="image.imageAlt"
                        (click)="showImage(i)"
                      />
                    </div>
                  </div>
                </div>
            
             
              </div>
            </div>
            

            <div class="product-carousel ">
              <!-- Left Arrow -->
              <button
              style="min-width: 20px !important ;  height: 20px !important; width: 20px; min-height: 20px !important;"
                class=" ea-button ea-button-secondary m-2 ml-2 left-arrow mobile"
                (click)="prevImage()"
                aria-label="Show previous image"
                [disabled]="currentIndex === 0"
              >
              <img src="assets/icons/left-row.svg"   style="max-width: 21px !important; width: 15px; margin-top: -11px; margin-left: -10px;"   alt="left row">
              </button>
            
              <!-- Main Image -->
              <div class="product-image mainImage mobile">
                <img
                  itemprop="image"
                  [src]="mainImage || 'assets/images/placeholder.png' | getImageFilter"
                  [alt]="altMainImage"
                  class="main-carousel-image"
                  aria-live="polite"
                />
              </div>
            
              <!-- Right Arrow -->
              <button
              style="min-width: 20px !important ;  height: 20px !important; width: 20px; min-height: 20px !important;"
                class="arrow-icon ea-button ea-button-secondary m-2 mr-2 right-arrow mobile"
                (click)="nextImage()"
                aria-label="Show next image"
                [disabled]="currentIndex === images.length - 1"
              >
              <img src="assets/icons/right-row.svg"  style="max-width: 21px !important; width: 14px; margin-top: -11px; margin-left: -5px;"  alt="right row">
              </button>
            </div>
            
            
            </ng-container>
  
            <!-- END PRODUCT IMAGE GALLERY END-->
          </div>
  
          <div
            class="product-display-left ps-5 col-sm-12 col-md-12 col-lg-6 col-xl-6"
          >
            <div>


              <div class="row desktop mt-4" style="background-color: #181618; color: white; padding: 10px; align-items: center; text-align: center;" tabindex="0">
                <span class="col-11">
                  <span class="font-bold" style="font-size: 19px;">Free standard delivery on all orders over £28</span><br>
                 <span  style="font-size: 18px;">Applies automatically on payment page</span> </span>
                <span class="col-1 mt-3" >      <div class="d-flex  justify-content-end text-right" style="align-items: end; text-align: end;">
                  <div class="mb-0 mr-2 p-0" (click)="triggerLike()">
                    <span
                    style="align-items: end; text-align: end;"
                      class="m-2 like "
                      *ngIf="product.products[0].userLiked"
                    >
                      <mat-icon >favorite</mat-icon>
                    </span>
                    <span
                      class="m-2 unlike "
                      *ngIf="!product.products[0].userLiked"
                    >
                      <mat-icon class="unlike" style=" color: white;">favorite_border</mat-icon>
                    </span>
                  </div>
                </div></span>
              </div>
        
              <br />
              <!-- <div class="d-flex">
                <div class="mb-0 mr-2 p-0" (click)="triggerLike()">
                  <span
                    class="m-2 like product-display-right"
                    *ngIf="product.products[0].userLiked"
                  >
                    <mat-icon>favorite</mat-icon>
                  </span>
                  <span
                    class="m-2 unlike product-display-right"
                    *ngIf="!product.products[0].userLiked"
                  >
                    <mat-icon class="unlike">favorite_border</mat-icon>
                  </span>
                </div>
                <a
                  *ngIf="isShowLinkForAccount"
                  class="like"
                  href="account"
                >
                  My Favourite
                </a>
              </div> -->
  
              <hr />
              <br />
              <h1 class="poduct-name" itemprop="name"  tabindex="0">{{ this.displayName }}</h1>
              
              <div class="mt-4 ">
                <ea-item-price
                [price]="livePrice?.price"
                [priceRange]="firstProduct?.priceRange"
                [isChanged]="isChanged"
               [productName]="displayName"
               (triggerLikes)="triggerLike()"
               [isLike]="product.products[0].userLiked"
              ></ea-item-price>

              <!-- <div class="d-flex mobile">
                <div class="mb-0 mr-2 p-0" (click)="triggerLike()">
                  <span
                    class="m-2 like product-display-right"
                    *ngIf="product.products[0].userLiked"
                  >
                    <mat-icon>favorite</mat-icon>
                  </span>
                  <span
                    class="m-2 unlike product-display-right"
                    *ngIf="!product.products[0].userLiked"
                  >
                    <mat-icon class="unlike">favorite_border</mat-icon>
                  </span>
                </div>
                <a
                  *ngIf="isShowLinkForAccount"
                  class="like"
                  href="account"
                >
                  My Favourite
                </a>
              </div> -->
              </div>
            </div>
  
            <div class=" add-cart-dev" >
              <div class="row">
                <div class="col-lg-6 col-sm-12 mt-1" *ngIf="sizes.length > 0"  tabindex="0">
                  <label for="size" style="font-weight: bold" class="mb-3">Size</label>
                  <div>
                    <select
                      id="size"
                      [(ngModel)]="this.selectedSize"
                      (ngModelChange)="ChooseSizeAndColor('size')"
                      class="form-select bg-theme-surface focus-outline text-theme-on-surface"
                    >
                      <option></option>
                      <option
                        *ngFor="let x of sizes"
                        [value]="x.value"
                        [disabled]="!x.hasStock"
                        class="bg-theme-surface focus-outline text-theme-on-surface"
                        [attr.aria-label]="getSizeAlt(x.value)"
                      >
                        {{ x.value }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 mt-1" *ngIf="colors.length > 0">
                  <label for="color" style="font-weight: bold" class="mb-3">Color</label>
                  <select
                  id="color"
                  [(ngModel)]="this.selectedColor"
                  (ngModelChange)="ChooseSizeAndColor(this.colorKey)"
                  class="form-select bg-theme-surface focus-outline text-theme-on-surface"
                >
                  <option></option>
                  <option
                    *ngFor="let x of colors"
                    [value]="x.value"
                    [disabled]="!x.hasStock"
                    class="bg-theme-surface focus-outline text-theme-on-surface"
                    [attr.aria-label]="x.value"
                  >
  
                      {{ x.value }}
                  </option>
                </select>
                </div>
                <div
                  *ngIf="colors.length == 0 && sizes.length == 0 && this.variants.length > 0"
                >
                  <label for="Choices" style="font-weight: bold" class="mb-3">Choices</label>
                  <select
                  id="Choices"
                  [(ngModel)]="this.selectedName"
                  (ngModelChange)="selectName()"
                  class="form-select bg-theme-surface focus-outline text-theme-on-surface"
                  *ngIf="colors.length == 0 && sizes.length == 0"
                >
                  <option></option>
                  <option
                    *ngFor="let x of variants"
                    [value]="x.itemId"
                    [disabled]="!x.hasStock"
                  >
                    <span
                      class="bg-theme-surface focus-outline text-theme-on-surface"
                    >
                      {{ x.name }}
                    </span>
                  </option>
                </select>
                </div>
              </div>
              <ea-product-add-to-basket
              [product]="addProductItem"
              [eventModel]="eventModel"
              [breadcrumbsString]="breadcrumbsString"
              [selectedItem]="selectedItem"
            ></ea-product-add-to-basket>
  
              <br />
            </div>
            <div class="toast-div" tabindex="0">
              <ea-toast 
              *ngIf=" this.productService.toast"
              [toast]=" this.productService.toast"
              (dispose) ="closeToast()"
              ></ea-toast>

              <div
              *ngIf="!this.productService.toast"
              class="toast toast-info d-flex justify-content-center"
            >
              
              <!-- Message Section -->
              <div >
                <p class="toast-msg d-flex justify-content-center" style="font-size: 18px;">We will donate some of our fees to charity as our commitment to accessibility for everyone </p>
              </div>
            </div>
            
            </div>
       
  
            <div *ngIf="product" class="product-sold-by" tabindex="0">
              <p class="text-theme-on-surface" style="font-size: 32px;">
                Sold by
                <a
                  [routerLink]="[
                    '/vendors/',
                    this.vendor_node_id,
                    this.vendor_id
                  ]"
                 
                  ><strong> <span  style="font-size: 34px;">{{ this.vendor_name }}</span></strong>
                </a>
                With Delivery options
                <br>
              </p>
              <div style=" margin-top: 40px;" class="mb-3" tabindex="0">

                <div *ngFor="let shipMethod of product.products[0].shipMethods" class=" mt-3">
                  <div class="row">
                    <span class="col-9">{{shipMethod.description}}</span>
                    <span class="col-3" style="text-align: end; font-weight: bold;" *ngIf="shipMethod.cost > 0">{{shipMethod.cost | currency :shipMethod.currency}}</span>
                    <span class="col-3" style="text-align: end; font-weight: bold;" *ngIf="shipMethod.cost == 0">Free</span>
  
                  </div>
                 <ul style="margin-left: 15px; margin-bottom: 22px;">
                  <li *ngIf="shipMethod.cutOffTime > 0">
                  <span> Order by {{this.getFormattedCutOffTime(shipMethod.cutOffTime)}}</span>
                  </li>
                  <li *ngIf="shipMethod.minimumDays > 1 && shipMethod.maximumDays > 2">
                    <span>{{shipMethod.minimumDays}} to {{shipMethod.maximumDays}} working days</span>
                  </li>
                  <li *ngIf="shipMethod.minimumDays == 1 && shipMethod.maximumDays ==2">
                  <span>Next working day</span>  
                  </li>
                 </ul>
                </div>
              </div>
            </div>
            <!-- <div
              class="row mt-3 container"
              *ngIf="
                firstProduct &&
                firstProduct.options &&
                firstProduct.options.length > 0
              "
            >
              <div *ngFor="let option of firstProduct.options" class="mt-2">
                {{ option.displayName }}
                <div class="col-12" *ngIf="option.type == 'text'">
                  <textarea
                    rows="5"
                    style="width: 100%"
                    (ngModelChange)="selectOption(option)"
                    [(ngModel)]="option.selectedValue"
                  >
                  </textarea>
                </div>
                <div class="col-12" *ngIf="option.type == 'list'">
                  <select
                    class="form-select bg-theme-surface focus-outline text-theme-on-surface"
                    [(ngModel)]="option.selectedValue"
                    (ngModelChange)="selectOption(option)"
                  >
                    <option></option>
                    <option *ngFor="let x of option.choices" [value]="x">
                      <span
                        class="bg-theme-surface focus-outline text-theme-on-surface"
                        >{{ x.name }}</span
                      >
                    </option>
                  </select>
                </div>
              </div>
            </div> -->
     
            <div *ngFor="let item of textList" class="mt-5">
              <hr   *ngIf="item.name =='Pre order information'" >
              <div class="product-description mt-5"  *ngIf="item.name =='Pre order information' ">
                <div class="text-theme-on-surface" tabindex="0">
                  <span style="font-size: 25px;" class="mb-3">
                    {{ item.name }}
                  </span>
                  <hr />
                  <div
                    class="mt-3"
                    *ngIf="item.type == 'display_bulletpoint_list'"
                  >
                  <ul class="m-3">
                      <li *ngFor="let point of this.item.value" class="mt-1">
                        <span
                          *ngIf="
                            point[0] == '-' &&
                            point != '' &&
                            point != 'Product Features:' &&
                            point
                          "
                        >
                          {{ point.replace("-", "") }}
                        </span>
                        <span
                          *ngIf="
                            point[0] == '•' &&
                            point != '' &&
                            point != 'Product Features:' &&
                            point
                          "
                        >
                          {{ point.replace("•", "") }}
                        </span>
                        <span
                          *ngIf="
                            point[0] != '-' &&
                            point[0] != '•' &&
                            point != '' &&
                            point != 'Product Features:' &&
                            point
                          "
                        >
                          {{ point }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
  
            <div *ngIf="desc.length > 0" class="product-description mt-5 "tabindex="0">
              <hr />
              <div class="text-theme-on-surface mt-5">
                <span style="font-size: 25px;" class="mb-3">
                  Product description
                </span>
  
  
                <div itemprop="description" [innerHTML]="this.desc"></div>
              </div>
            </div>
            <div *ngFor="let item of textList" class="mt-5" >
              <hr *ngIf=" item.name != 'Product specifications'"/>
              <div class="product-description mt-5" *ngIf=" item.name !='Pre order information' && item.name != 'Product specifications'" tabindex="0">
                <div class=" text-theme-on-surface">
                  <span style="font-size: 25px;" class="mb-3">
                    {{ item.name }}
                  </span>
                  <div
                    [innerHTML]="item.value[0]"
                    *ngIf="item.type == 'display_text'"
                  ></div>
                  <div
                    class="mt-3"
                    *ngIf="item.type == 'display_bulletpoint_list' "
                  >
                  <ul class="m-3">
                      <li *ngFor="let point of this.item.value" class="mt-1">
                        <span
                          *ngIf="
                            point[0] == '-' &&
                            point != '' &&
                            point != 'Product Features:' &&
                            point
                          "
                        >
                          {{ point.replace("-", "") }}
                        </span>
                        <span
                          *ngIf="
                            point[0] == '•' &&
                            point != '' &&
                            point != 'Product Features:' &&
                            point
                          "
                        >
                          {{ point.replace("•", "") }}
                        </span>
                        <span
                          *ngIf="
                            point[0] != '-' &&
                            point[0] != '•' &&
                            point != '' &&
                            point != 'Product Features:' &&
                            point
                          "
                        >
                          {{ point }}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="mt-3" *ngIf="item.type == 'display_value_list'">
                    <div class="mb-2" *ngFor="let point of this.item.value">
                      <span *ngIf="point[0] == '-'">
                        {{ point.replace("-", "") }}
                      </span>
                      <span *ngIf="point[0] != '-'">
                        {{ point }}
                      </span>
  
                      <br />
                      <br />
                      <hr />
                    </div>
                  </div>
                  <div
                    class="mt-3"
                    *ngIf="item.type == 'display_key_value_with_bullet_list'"
                  >
                    <table style="width: 100%">
                      <tr class="mt-1" *ngFor="let point of this.item.value">
                        <td class="m-2" *ngFor="let i of point.split('%')">
                          <span *ngIf="i[0] == '-'">
                            <span
                              *ngIf="
                                i == point.split('%')[0] &&
                                point.split('%').length > 1
                              "
                              >{{ i.replace("-", "") }}%</span
                            >
                            <span
                              *ngIf="
                                i != point.split('%')[0] ||
                                point.split('%').length == 1
                              "
                              >{{ i.replace("-", "") }}</span
                            >
                          </span>
                          <span *ngIf="i[0] != '-'">
                            <span
                              *ngIf="
                                i == point.split('%')[0] &&
                                point.split('%').length > 1
                              "
                              >{{ i }}%</span
                            >
                            <span
                              *ngIf="
                                i != point.split('%')[0] ||
                                point.split('%').length == 1
                              "
                              >{{ i }}</span
                            >
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
         
                  <div
                    class="mt-3"
                    *ngIf="
                      item.type == 'display_key_value_with_bullet_list_colon'
                    "
                  >
                    <table style="width: 100%">
                      <tr class="mt-1" *ngFor="let point of this.item.value">
                        <td class="m-2" *ngFor="let i of point.split(':')">
                          <span *ngIf="i[0] == '-'">
                            <span
                              *ngIf="
                                i == point.split('%')[0] &&
                                point.split(':').length > 1
                              "
                            >
                              {{ i.replace("-", "") }}
                            </span>
                            <span
                              *ngIf="
                                i != point.split('%')[0] ||
                                point.split(':').length == 1
                              "
                            >
                              {{ i.replace("-", "") }}
                            </span>
                          </span>
                          <span *ngIf="i[0] != '-'">
                            <span
                              *ngIf="
                                i == point.split('%')[0] &&
                                point.split(':').length > 1
                              "
                            >
                              {{ i }}
                            </span>
                            <span
                              *ngIf="
                                i != point.split('%')[0] ||
                                point.split(':').length == 1
                              "
                            >
                              {{ i }}
                            </span>
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
  
                </div>
              </div>
            </div>
            <hr  *ngIf=" specifications.length > 0" class="mt-5" />
            <div
            tabindex="0"
            class="mt-5"
            *ngIf=" specifications.length > 0"
          >
          <span style="font-size: 25px;">
            Product specifications
          </span>

          <table style="width: 100%; border-collapse: collapse; border: none;" class="mt-4">
            <tr *ngFor="let point of specifications; let index = index" 
            [ngClass]="{'table-cell': index !== specifications.length - 1 ,
             'last-table-cell' :  index === specifications.length - 1}">
          <td class="text-left first-cell m-2">
            <span>{{ transformName(point.name) }}</span>
          </td>
          <td class="text-rights p-2" >
            <span>    {{ point.value === 'true' ? 'Yes' : point.value === 'false' ? 'No' : point.value }}</span>
          </td>
        </tr>

    
          </table>
          
          </div>
          </div>
          <div
            class="product-container ml-2"
            data-read-mode-ignore
            *ngIf="data && data.data && data.data.length > 0"
          >
            <h1  tabindex="0" class="mt-2  poduct-name" alt="Products you may also like">
              Products you may also like
            </h1>
            <div id="products" #products class="products-grid justify-content-center">

              <ea-product-grid
              (click)="loadProduct()"
              *ngFor="let product of data.data; let index = index"
              [product]="product"
              [correlationId]="correlationId"
              [index]="index"
              [clientUUID]="this.clientId"
            ></ea-product-grid>
            </div>
          </div>
        </div>
  
        <ng-container *ngIf="!product">
          <h2>No Product</h2>
        </ng-container>
      </div>
    </section>
  </div>
  }
  